import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import Button from '../../Button/Button';
import Divider from '../../Divider/Divider';
import FormGroupInput from '../../Form/FormGroupInput/FormGroupInput';
import SocialLogins from '../SocialLogins/SocialLogins';
import LoginValidationSchema from './LoginValidationSchema';
import { pathnames } from '../../../i18n/pathnames';
import Typography from '../../Typography/Typography';
import FormError from '../../Form/FormError/FormError';
import Box from '../../Box/Box';
import { getValidationSchema, validateForm } from '../../../utils/formUtil';
import { LOGIN_FORM_FIELDS, LOGIN_FORM_ID } from 'constants/authentication';
import { FontWeight } from 'constants/styling';
import { LocalizedLink } from 'components/Router';

interface LoginFormProps {
  onSubmit: ({ email, password }: { email: string; password: string }) => void;
  passwordForgetFontWeight?: FontWeight;
}

const LoginForm = ({ onSubmit, passwordForgetFontWeight = 'bold' }: LoginFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form id={LOGIN_FORM_ID} onSubmit={handleSubmit}>
          <FormError />

          <FormGroupInput
            id={LOGIN_FORM_FIELDS.EMAIL.id}
            label={formatMessage({
              id: LOGIN_FORM_FIELDS.EMAIL.label,
            })}
            name={LOGIN_FORM_FIELDS.EMAIL.name}
            type={LOGIN_FORM_FIELDS.EMAIL.type}
          />

          <FormGroupInput
            id={LOGIN_FORM_FIELDS.PASSWORD.id}
            label={formatMessage({
              id: LOGIN_FORM_FIELDS.PASSWORD.label,
            })}
            name={LOGIN_FORM_FIELDS.PASSWORD.name}
            type={LOGIN_FORM_FIELDS.PASSWORD.type}
          />

          <Button fullwidth loading={submitting} size="large" type="submit">
            {formatMessage({ id: 'login_form_submit' })}
          </Button>

          <Typography
            fontSize={88}
            fontWeight={passwordForgetFontWeight}
            marginTop={100}
            textAlign="center"
            textDecoration="none"
            type="p"
          >
            <LocalizedLink name={pathnames.PASSWORD_FORGOTTEN_REQUEST}>
              {formatMessage({
                id: 'login_password_forgotten',
              })}
            </LocalizedLink>
          </Typography>

          <Box marginTop={200}>
            <Divider
              text={formatMessage({
                id: 'general_or_text',
              })}
            />
            <SocialLogins />
          </Box>
        </form>
      )}
      validate={validateForm(getValidationSchema(LoginValidationSchema))}
    />
  );
};

export default LoginForm;
