export const BORDER_TEMPLATES = {
  BORDER: 'BORDER',
  BORDERLESS: 'BORDERLESS',
};

export const SLOT_TEMPLATES = {
  MASONRY: 'MASONRY',
};

export const CMS_COMPONENT_TYPES = {
  CMS_PRODUCTS_GROUP_DATA: 'cmsProductsGroupData',
  CMS_USP_COMPONENT: 'cmsuspComponentWsDTO',
} as const;

export const CMS_COMPONENT_TYPE_CODES = {
  CMS_PARAGRAPH_COMPONENT: 'CMSParagraphComponent',
};

export const CF_PRODUCT_TILE_PAGE_SIZE = 15;
