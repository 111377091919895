import { CMSUspComponent as CMSUspComponentType } from 'types/CMS';
import Typography from '../../../Typography/Typography';

type CMSUspComponentProps = {
  className?: string;
  uspComponent?: CMSUspComponentType;
};

const CMSUspComponent = ({ className, uspComponent }: CMSUspComponentProps) => {
  if (!uspComponent) return null;
  const uspItems = uspComponent?.uspConfiguration?.uspItems;

  return (
    <Typography className={className} color="">
      {uspItems?.map((usp) => usp?.title)}
    </Typography>
  );
};

export default CMSUspComponent;
