import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import FormValidationSettings from '../../../constants/formValidation';

const { email, password } = FormValidationSettings.validation;

const ValidationSchema = {
  email: yup
    .string()
    //@ts-ignore
    .matches(email.regex, {
      message: <FormattedMessage id="form_validation_email" />,
    })
    .max(email.length.max, (obj) => <FormattedMessage id="form_validation_max" values={{ max: obj.max.toString() }} />)
    //@ts-ignore
    .required(<FormattedMessage id="form_validation_required" />),
  password: yup
    .string()
    .max(password.length.max, (obj) => (
      <FormattedMessage id="form_validation_max" values={{ max: obj.max.toString() }} />
    ))
    .min(password.length.min, (obj) => (
      <FormattedMessage id="form_validation_min" values={{ min: obj.min.toString() }} />
    ))
    //@ts-ignore
    .required(<FormattedMessage id="form_validation_required" />),
};

export default ValidationSchema;
