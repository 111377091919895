import { useEffect, useMemo, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import config from '../config';

type Size = {
  height: number;
  width: number;
};

/**
 * @warning Do not use this hook in the server side or for changing the DOM, this will result in hydration mismatch.
 */
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>();
  const windowWidth = windowSize?.width === undefined ? config.breakpoints.desktop - 1 : windowSize.width;

  const handleResize = useDebounceCallback(
    () =>
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      }),
    300,
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSmall = useMemo(() => windowWidth < config.breakpoints.small, [windowSize]);
  const isMobile = useMemo(() => windowWidth < config.breakpoints.mobile, [windowSize]);
  const isTablet = useMemo(() => windowWidth < config.breakpoints.tablet, [windowSize]);
  const isDesktop = useMemo(() => windowWidth < config.breakpoints.desktop, [windowSize]);
  const isWidescreen = useMemo(() => windowWidth < config.breakpoints.desktop, [windowSize]);
  const isXXL = useMemo(() => windowWidth >= config.breakpoints.desktop_xxl, [windowSize]);

  return {
    height: windowSize?.height,
    isDesktop,
    isMobile,
    isSmall,
    isTablet,
    isWidescreen,
    isXXL,
    width: windowWidth,
  };
};

export default useWindowSize;
