/* eslint-disable sort-keys-fix/sort-keys-fix */

import { CURRENT_BRAND } from './brand';

export const FORMATS = {
  CATEGORY: {
    BANNER_MOBILE: {
      h: 200,
      w: 720,
    },
    BANNER_WIDE: {
      h: 200,
      w: 1050,
    },
  },
  RESPONSIVE_IMAGE: [100, 500, 700, 1050, 1200],
};

export const STATIC_IMAGES = {
  DELIVERY_TICKET_1: 'delivery_ticket_1',
  DELIVERY_TICKET_2: 'delivery_ticket_2',
  DELIVERY_TICKET_3: 'delivery_ticket_3',
  DELIVERY_TICKET_4: 'delivery_ticket_4',
  DEMODAYS: 'demodays',
  ECOVOUCHERS_PAYMENT: 'payment_ecocheques',
  ERROR: 'error',
  ERROR_500: 'error_500',
  EVOUCHER_NUMBERS: 'evoucher_numbers',
  GIFTCARD: 'giftcard',
  GIFTCARD_NUMBERS: 'giftcard_numbers',
  GIFTCARD_PAYMENT_ICON: 'giftcard_payment_icon',
  LOGO: 'logo',
  NO_RESULTS: 'no_results',
  PAYCONIQ_BG: 'payconiqFrame',
  PAYCONIQ_INFO: 'payconiq-payment-explanation',
  RECEIPT: 'receipt',
  YOUTUBE_PLAY: 'youtube-play-button',
} as const;
export type StaticImage = (typeof STATIC_IMAGES)[keyof typeof STATIC_IMAGES];

export const VIMEO_REGEX =
  /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
export const YOURUBE_REGEX = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

const MEDIA_URL_FALLBACKS = {
  krefel: `https://media.krefel.be`,
  hifi: `https://media.hifi.lu`,
  tones: `https://media.tones.be`,
};

export const MEDIA_URL = process.env.NEXT_PUBLIC_MEDIA_URL || MEDIA_URL_FALLBACKS[CURRENT_BRAND];
