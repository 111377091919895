import { FlyOver } from 'components/Layout';
import { RegisterValidationSchemaType } from 'components/Register/RegisterForm/RegisterFormValidationSchema';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { pathnames } from 'i18n/pathnames';
import { memo, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { useAuthActions, useAuthFlyoverVisible } from 'store/auth';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { log } from 'utils/loggerUtil';
import { ALERT_TYPES } from '../../../constants/alerts';
import { ERROR_CODES } from '../../../constants/errors';
import { useErrorMessages } from '../../../hooks/useErrorMessages';
import useRegisterUser from '../../../hooks/useRegisterUser';
import Alert from '../../Layout/Alert/Alert';
import RegisterForm from '../../Register/RegisterForm/RegisterForm';
import LoginForm from '../LoginForm/LoginForm';

const AuthFlyOver = () => {
  const { asPath, locale, pathname } = useRouter();
  const { formatMessage } = useIntl();
  const { onPageView } = useTrackingEvents();

  const { errorMessages: registrationErrorMessages, onRegister, setIsRegistering } = useRegisterUser();
  const { getErrorMessage } = useErrorMessages();

  const visible = useAuthFlyoverVisible();
  const { user } = useAuthResolver();
  const { login, setAuthFlyoverVisible } = useAuthActions();

  const [loginErrorMessages, setLoginErrorMessages] = useState<string[]>();
  const allErrorMessages = [...(registrationErrorMessages || []), ...(loginErrorMessages || [])];
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  const resetStates = () => {
    setLoginErrorMessages(undefined);
    setIsRegistering(false);
  };

  const toggleFlyover = () => {
    resetStates();
    setShowRegisterForm(false);
    setAuthFlyoverVisible(false);
  };

  const toggleRegisterForm = () => {
    resetStates();
    setShowRegisterForm(!showRegisterForm);
  };

  const onFlyOverLogin = async ({ email, password }: { email: string; password: string }) => {
    try {
      await login({ email: email.toLowerCase(), password });
      toggleFlyover();
    } catch (err) {
      const errorMessage = getErrorMessage({ type: ERROR_CODES.LOGIN_ERROR });
      errorMessage && setLoginErrorMessages([errorMessage]);
    }
  };

  const onFlyOverRegister = async (registerFormValues?: RegisterValidationSchemaType) => {
    try {
      await onRegister({ registerFormValues, toggleFlyover });
    } catch (err) {
      log('onFlyOverRegister', 'error', err);
    }
  };

  useEffect(() => {
    toggleFlyover();
  }, [pathname]);

  useEffect(() => {
    if (visible) {
      const pathnameOverride = showRegisterForm ? pathnames.REGISTER : pathnames.LOGIN;
      onPageView({ locale, path: asPath, pathname: pathnameOverride, user });
    }
  }, [visible, showRegisterForm]);

  return (
    <FlyOver
      side="right"
      title={formatMessage({ id: 'header_main_nav_account_my_account' })}
      open={visible}
      onOpenChange={toggleFlyover}
    >
      <div className="mb-4 flex flex-col items-center">
        <Text type="h2" className="mb-4">
          {formatMessage({
            id: showRegisterForm ? 'login_register_title' : 'login_title',
          })}
        </Text>

        <Text>
          {formatMessage({
            id: showRegisterForm ? 'login_register_subtitle' : 'login_subtitle',
          })}
        </Text>

        <div>
          <Text className={cn('inline-block', showRegisterForm ? 'hidden' : '')}>
            {formatMessage({
              id: 'login_registration_title_text',
            })}
          </Text>{' '}
          <Text
            className="inline-block font-bold text-primary-40"
            onClick={toggleRegisterForm}
            role="button"
            tabIndex={0}
          >
            {formatMessage({
              id: 'login_register_btn',
            })}
          </Text>
        </div>
      </div>

      {allErrorMessages?.map((errorMessage) => (
        <Alert key={errorMessage} type={ALERT_TYPES.DANGER}>
          {errorMessage}
        </Alert>
      ))}

      {!showRegisterForm && <LoginForm onSubmit={onFlyOverLogin} />}

      {showRegisterForm && <RegisterForm onCancel={toggleRegisterForm} onSubmit={onFlyOverRegister} />}
    </FlyOver>
  );
};

export default memo(AuthFlyOver, isEqual);
